import { render, staticRenderFns } from "./amazonec2.vue?vue&type=template&id=2e0c7ba8&scoped=true&"
import script from "./amazonec2.vue?vue&type=script&lang=js&"
export * from "./amazonec2.vue?vue&type=script&lang=js&"
import style0 from "./amazonec2.vue?vue&type=style&index=0&id=2e0c7ba8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e0c7ba8",
  null
  
)

export default component.exports